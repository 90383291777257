@charset "UTF-8";


/*------------------------------------*\
    #BASE
\*------------------------------------*/
/**
 * 1. Set the base element’s `font-size` to the value of your choosing. Set in
 *    ems, assuming a browser default of 16px.
 * 2. Work out the unitless `line-height` for your project based around your
 *    desired `line-height` (defined previously in pixels), and your project’s
 *    base font size.
 */
html {
  font-size: 1.125em;
  /* [1] */
  line-height: 1.5555555556;
  /* [2] */ }

body {
  margin: 0; }

/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/


h1, h2 {
  font-size: 36px;
  font-size: 2rem;
  line-height: 1.5555555556; }

h3 {
  font-size: 25px;
  font-size: 1.3888888889rem;
  line-height: 1.12; }

h4 {
  font-size: 20px;
  font-size: 1.1111111111rem;
  line-height: 1.4; }

h5 {
  font-size: 15px;
  font-size: 0.8333333333rem;
  line-height: 1.8666666667; }

h6 {
  font-size: 15px;
  font-size: 0.8333333333rem;
  line-height: 1.8666666667; }

/*------------------------------------*\
    #LISTS
\*------------------------------------*/
ul, ol, dd {
  margin-left: 56px;
  margin-left: 3.1111111111rem;
  margin-left: 1em; }

li > ul,
li > ol {
  margin-bottom: 0; }

/*------------------------------------*\
    #PARAGRAPHS
\*------------------------------------*/
small {
  font-size: 15px;
  font-size: 0.8333333333rem;
  line-height: 1.8666666667; }

/**
 * Not strictly a paragraph, but probably doesn’t need its own section.
 */
/*------------------------------------*\
    #CODE
\*------------------------------------*/
/**
 * 1. Fix an odd quirk whereby, without this, code blocks are rendered at a
 *    font-size smaller than 1em.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* [1] */ }



/*------------------------------------*\
    #TABLES
\*------------------------------------*/
/**
 * Crude table styles; tables are very difficult to keep on the baseline.
 */
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }

th,
td {
  padding: 14px; }

/* Fonts
   ========================================================================== */
html {
  /* color: #888; */
  @apply text-neutral-500;
  font-family: "Graphik Web", sans-serif;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  background: white; }

button, input, select, textarea {
  font-family: "Graphik Web", sans-serif;
  font-weight: 300;
  text-rendering: optimizeLegibility; }

.giga {
  font-size: 1.618em;
  font-weight: 900; }

.mega {
  font-size: 44px;
  font-size: 2.4444444444rem; }
  @media screen and (max-width: 480px) {
    .mega {
      font-size: 72px;
      font-size: 4rem; } }
h1,
.alpha {
  @apply text-neutral-900;
  font-weight: 700;
  margin-bottom: 0; }

h2,
.beta {
  font-weight: 500;
  font-family: "Graphik Web", sans-serif;
  font-size: 36px;
  margin-bottom: 0.4045em; }
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    h2,
    .beta {
      font-size: 25px; } }
  @media screen and (max-width: 480px) {
    h2,
    .beta {
      font-size: 25px; } }
h3,
.gamma {
  @apply text-neutral-900;
  font-weight: 500;
  font-size: 18px;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Graphik Web", sans-serif; }

h4,
.delta {
  font-family: "Graphik Web", sans-serif;
  font-size: 18px;
  font-size: 1rem;
  font-weight: 400;
  color: #222222; }
  @media screen and (max-width: 480px) {
    h4,
    .delta {
      font-size: 22px;
      font-size: 1.2222222222rem; } }
h5,
.epsilon {
  font-size: 15px;
  @apply text-neutral-500;
  line-height: 1.5em;
  margin: 0;
  font-weight: 400; }
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    h5,
    .epsilon {
      font-size: 1em; } }
h6,
.zeta {
  font-weight: 400;
  color: #bbb;
  font-size: 15px; }
  @media screen and (max-width: 480px) {
    h6,
    .zeta {
      font-size: 18px;
      font-size: 1rem; } }
p,
ul,
ol,
dl {
  margin: 0 0 0.5em;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -hyphens: auto; }
  p:last-child,
  ul:last-child,
  ol:last-child,
  dl:last-child {
    margin-bottom: 0; }

dt {
  font-weight: 400; }

.lead {
  font-size: 20px;
  font-size: 1.1111111111rem; }
  @media screen and (max-width: 480px) {
    .lead {
      font-size: 24px;
      font-size: 1.3333333333rem; } }
i {
  color: #bbb; }

/* Defaults
   ========================================================================== */
html {
  background-color: #f8f8f8; }

body {
  font-size: 18px;
  line-height: 1.618em; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

/* figure {
  margin-bottom: 1.8333333333rem; }
  figure img {
    display: block;
    margin-bottom: 0.6111111111rem; } */

@media screen and (max-width: 480px) {
  figcaption {
    font-size: 22px;
    font-size: 1.2222222222rem; } }

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle; }



pre {
  margin: 0; }

strong {
  font-weight: 500;
  color: #333; }

code {
  color: #C14B3E;
  font-family: 'Inconsolata', sans-serif; }
  @media screen and (max-width: 480px) {
    code {
      font-size: 22px;
      font-size: 1.2222222222rem; } }
a {
  @apply text-neutral-500;
  text-decoration: none;
  outline: none;
  transition: color 0.8s ease-in-out; }
  a:hover {
    transition: all 0.2s ease-in-out;
    color: inherit;
    outline: none; }
  a:active {
    outline: none;
    color: inherit; }
  a:focus {
    outline: none;
    color: inherit; }

::-webkit-input-placeholder {
  color: #ccc; }

::-moz-placeholder {
  color: #ccc; }

/* Forms
   ========================================================================== */
fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  line-height: 1.6;
  resize: vertical; }

label {
  cursor: pointer; }

input[type=text],
input[type=search],
input[type=email],
input[type=url],
textarea {
  font-size: 1em;
  -webkit-appearance: none;
  border: 5px solid #fff;
  box-sizing: border-box;
  color: #ccc;
  outline: none;
  padding: 1.2em;
  transition: all .25s ease;
  width: 100%; }
  input[type=text]:focus,
  input[type=search]:focus,
  input[type=email]:focus,
  input[type=url]:focus,
  textarea:focus {
    border-color: #f8f8f8;
    color: inherit; }

.btn {
  font-weight: 300;
  letter-spacing: .05em;
  text-transform: uppercase; }

.media:before, .l-grid:before, .media:after, .l-grid:after {
  content: "";
  display: table; }

.media:after, .l-grid:after {
  clear: both; }

.l-stacked,
.l-inline,
.l-inline-desktop,
.l-grid {
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  padding-left: 0; }

.content {
  transition: all 0.5s ease-in-out; }

.txt-right {
  text-align: right; }

.padding-bottom {
  margin-bottom: 4.854em; }

.padding-top {
  margin-top: 4.854em; }

.pl {
  padding-left: 1.618rem; }

.pt {
  padding-top: 1.618em; }

.pt- {
  padding-top: 0.809em; }

.pt-- {
  padding-top: 0.5393333333em; }

.pt--- {
  padding-top: 0.4045em; }

.pb {
  padding-bottom: 1.618em; }

.pb- {
  padding-bottom: 0.809em; }

.pb-- {
  padding-bottom: 0.5393333333em; }

.pb--- {
  padding-bottom: 0.4045em; }

.pb\+ {
  padding-bottom: 3.236em; }

.pb\+\+ {
  padding-bottom: 4.854em; }

.mt {
  margin-top: 1.618em; }

.mt\- {
  margin-top: 0.809em; }

.\-\mt {
  margin-top: -1.618em; }

.\-\mt\- {
  margin-top: -0.809em; }

.mt\+ {
  margin-top: 3.236em; }

.only-mobile {
  display: none; }
  @media screen and (max-width: 1200px) {
    .only-mobile {
      display: block; } }
.only-desktop {
  display: none; }
  @media all and (min-width: 1200px) {
    .only-desktop {
      display: block; } }
/* ==========================================================================
	 Layout
	 ========================================================================== */
/**
 * Layout styles for lists.
 *
 * Used for list items to remove the bullets and margins. The different classes
 * refer to the different layouts you want to use.
 */
.l-inline > li {
  display: inline-block; }

.l-grid > li {
  float: left; }

/* Custom styles for Barebones
	 ========================================================================== */
.l-inline-desktop > li {
  margin-bottom: 0.2222222222rem; }
  @media screen and (max-width: 480px) {
    .l-inline-desktop > li {
      display: inline-block;
      margin-bottom: 0; } }
.l-header {
  background-color: #fff;
  border-bottom: 1px solid #bbb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  padding-top: 0.6666666667rem;
  padding-bottom: 0.6666666667rem; }
  .l-header h1 {
    margin: 0; }
  @media screen and (max-width: 480px) {
    .l-header {
      display: none; } }
.l-footer {
  padding: 1.618em 0;
  text-align: left;
  bottom: 0;
  padding: 1.618em 4.854em;
  background: #fff;
  float: left;
  display: block;
  margin-right: 3.4482758621%;
  width: 100%; }
  .l-footer i {
    color: #4d4d4d;
    padding: 0 0.4045em; }
  .l-footer a {
    font-family: "Graphik Web", sans-serif; }
  @media screen and (max-width: 480px) {
    .l-footer {
      padding-top: 3.3333333333rem;
      padding-bottom: 3.3333333333rem; } }
.l-constrained {
  margin-right: auto;
  margin-left: auto; }
  @media screen and (max-width: 480px) {
    .l-constrained {
      padding-right: 1rem;
      padding-left: 1rem; } }
.l-row {
  margin-bottom: 2.7777777778rem; }
  @media screen and (max-width: 480px) {
    .l-row {
      margin-bottom: 4.8888888889rem; } }
.l-magazin-top {
  z-index: 99999;
  position: relative;
  margin-top: -8.09em; }

.l-magazin-top-extra {
  margin-top: -16.18em;
  z-index: 99999; }
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    .l-magazin-top-extra {
      margin-top: -8.09em; } }
.l-magazin-bottom {
  margin-bottom: -4.854em;
  display: inline-block;
  z-index: 7;
  position: relative;
  width: 100%;
  zoom: 1;
  position: relative; }

.l-center {
  text-align: center; }

.center-wrap {
  width: 80%;
  margin: 0 auto;
  padding: 3.236em 1.618em; }
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    .center-wrap {
      width: 100%; } }
.l-wrapper {
  padding: 0 4%; }

.g-wrapper {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }
  .g-wrapper::after {
    content: '';
    display: table;
    clear: both; }

.l-fullwidth {
  float: left;
  display: block;
  margin-right: 3.4482758621%;
  width: 100%; }

/*===============================================
=            architekt böll specifix            =
===============================================*/
.sideBar {
  float: right;
  background: #f8f8f8;
  overflow: hidden;
  width: 22.828282%;
  height: 100%;
  display: block;
  margin-right: 0; }

.sidebar-content {
  position: fixed;
  background: #f8f8f8;
  margin-top: 3.8em;
  width: 22.828282%;
  padding: 3%;
  height: 90%; }

.container {
  box-sizing: border-box;
  width: 100%;
  float: left; }

.content-wrapper {
  background: #fff; }

.content {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 7.676767% 7.676767% 0; }
  .content::after {
    content: '';
    display: table;
    clear: both; }

.l-header--mobile {
  display: block;
  background: #f8f8f8; }

footer {
  background: #f8f8f8;
  margin-bottom: 1.618em; }

/*-----  End of architekt böll specifix  ------*/
/* ==========================================================================
   Button
   ========================================================================== */
/**
 * Layout styles for lists.
 *
 * Used for list items to remove the bullets and margins. The different classes
 * refer to the different layouts you want to use.
 */
.btn {
  font-size: 1em;
  background-color: none;
  font-family: "Graphik Web", sans-serif;
  font-weight: 300;
  color: #cccccc;
  text-shadow: 0 1px 0 white;
  border: 5px solid #f2f2f2;
  display: inline-block;
  padding: .5em .9em; }
  .btn:hover {
    border-color: #d9d9d9;
    color: white;
    text-shadow: 0 -1px 0 #d9d9d9;
    box-shadow: inset 0px 1px #d9d9d9; }

.btn--big {
  font-size: 24px;
  font-size: 1.3333333333rem;
  padding: .75em 1em; }

.btn--small {
  font-size: 18px;
  font-size: 1rem;
  padding: .35em .7em; }

/* Custom styles for Barebones
   ========================================================================== */
.btn--alert {
  background-color: #c81b13;
  border-color: #ac1710;
  color: #fff; }

.btn--success {
  background-color: #0fc843;
  border-color: #0dac39;
  color: #fff; }

.btn--twitter {
  background-color: #00aced;
  border-color: #0096ce;
  color: #fff; }

.btn--facebook {
  background-color: #3b5999;
  border-color: #324c83;
  color: #fff; }

.btn--form {
  font-weight: 400;
  border: 5px solid #fff;
  color: #fff;
  display: inline-block;
  padding: .5em .9em;
  width: 37%;
  text-align: center;
  text-shadow: 0 -1px 0 #5e5954; }
  .btn--form:hover {
    background-color: #fff;
    color: #5e5954;
    text-shadow: none;
    border: 5px solid #fff;
    box-shadow: none; }
  @media screen and (max-width: 480px) {
    .btn--form {
      width: 50%;
      float: right;
      margin-top: 0.809em;
      margin-bottom: 3.236em; } }
.box {
  background-color: white;
  padding: 0.4045em 0; }

.box--sub {
  background: url(../images/bg-box-2.png);
  float: left;
  display: block;
  margin-right: 3.4482758621%;
  width: 100%; }
  .box--sub p {
    padding: 0 1.618em;
    margin-bottom: 0;
    color: white;
    text-shadow: #cccccc 0 -1px;
    text-align: center;
    font-weight: 500; }

.box--single p {
  text-align: left; }

.flag {
  display: table;
  width: 100%; }

.flag-pull-right,
.flag-pull-left,
.flag-body {
  display: table-cell;
  vertical-align: middle; }

.flag-body {
  width: 80%; }

.flag-pull-left {
  padding-right: 1.618em; }

.flag-pull-right {
  padding-left: 1.618em;
  text-align: right; }
  .flag-pull-right img {
    /* This is the only way to float it to the right for now */
    float: right; }

/* Custom styles for Barebones
   ========================================================================== */
@media screen and (max-width: 480px) {
  .flag-pull-left-desktop,
  .flag-pull-right-desktop,
  .flag-body-desktop {
    display: table-cell;
    vertical-align: middle; } }

.flag-pull-left-desktop {
  margin-bottom: 0.2222222222rem; }
  @media screen and (max-width: 480px) {
    .flag-pull-left-desktop {
      margin-bottom: 0; } }
@media screen and (max-width: 480px) {
  .flag-pull-left--btn {
    width: 50%; } }

.flag-pull-right iframe {
  /* Aligns the Twitter button */
  vertical-align: middle; }

.flag-body-desktop {
  text-align: right; }

.hero {
  height: 30.1111111111em;
  text-align: center; }
  .hero .giga,
  .hero .mega {
    font-weight: 700;
    margin-bottom: .25em;
    color: white; }
  @media screen and (max-width: 480px) {
    .hero {
      padding-bottom: 7.7777777778rem; } }
.teaser--home {
  z-index: -11 !important; }

.icon {
  margin-right: 0.4444444444rem; }

.media {
  padding-bottom: 3.236em; }
  .media:last-of-type {
    padding-bottom: 0; }

.media-pull-right {
  float: right;
  margin-left: 2.0625rem; }

.media-pull-left {
  float: left;
  margin-right: 2.0625rem; }

/*
 * 1. Create new block formatting context in modern browsers
 * 2. Avoid shrink-wrap behaviour of table-cell
 * www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/
 * facebook.com
*/
.media-body {
  display: table-cell;
  /* 1 */
  width: 10000px;
  /* 2 */ }

/* Custom styles for Barebones
   ========================================================================== */
.media-pull-right-desktop,
.media-pull-left-desktop {
  margin-bottom: 0.4444444444rem; }
  .media-pull-right-desktop img,
  .media-pull-left-desktop img {
    width: 100%; }
    @media screen and (max-width: 480px) {
      .media-pull-right-desktop img,
      .media-pull-left-desktop img {
        max-width: 100%; } }
  @media screen and (max-width: 480px) {
    .media-pull-right-desktop,
    .media-pull-left-desktop {
      margin-bottom: 0; } }
@media screen and (max-width: 480px) {
  .media-pull-right-desktop {
    float: right;
    margin-left: 2.0625rem; } }

@media screen and (max-width: 480px) {
  .media-pull-left-desktop {
    float: left;
    margin-right: 2.0625rem; } }

.rimg {
  width: 100%;
  height: auto; }
  .rimg img {
    width: 100%;
    height: auto; }

.bilder img {
  width: 100%;
  height: auto; }

figure {
  margin: 0; }

/* figcaption {
  padding-bottom: 1.618em; } */

.halb {
  @apply w-1/2; }

.halb-a {
  @apply w-1/2; }

.halb-b {
  @apply w-1/2;
  margin-right: 0; }

.halb-l {
  @apply w-1/2; }

.halb-r {
  @apply w-1/2; }

.center {
  width: auto;
  max-width: 1024px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0; }
  .center::after {
    content: '';
    display: table;
    clear: both; }

.center-h {
  width: auto;
  max-width: 750px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0; }
  .center-h::after {
    content: '';
    display: table;
    clear: both; }

.modal {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
  height: 19.3333333333rem;
  margin-top: -9.6666666667em;
  margin-left: -45%;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: opacity .25s ease-out .5s, -webkit-transform .25s ease-in-out .5s;
  transition: transform .25s ease-in-out .5s, opacity .25s ease-out .5s;
  transition: transform .25s ease-in-out .5s, opacity .25s ease-out .5s, -webkit-transform .25s ease-in-out .5s;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  width: 90%; }
  @media screen and (max-width: 480px) {
    .modal {
      height: 27.2777777778rem;
      margin-top: -13.6388888889rem;
      margin-left: -22.2222222222rem;
      max-width: 44.4444444444rem; } }
.modal-header,
.modal-footer {
  padding: 0.6666666667rem 1rem; }
  @media screen and (max-width: 480px) {
    .modal-header,
    .modal-footer {
      padding: 1rem 1.7777777778rem; } }
.modal-header {
  border-bottom: 1px solid #bbb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03); }

.modal-title {
  margin: 0; }

.modal-body {
  height: 11.1111111111rem;
  overflow-y: auto;
  padding: 0.6666666667rem 1rem; }
  @media screen and (max-width: 480px) {
    .modal-body {
      height: 16.6666666667rem;
      padding: 1rem 1.7777777778rem; } }
.modal-footer {
  border-top: 1px solid #bbb;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.03); }

.nav--pagination {
  width: 100%;
  display: inline-block; }
  .nav--pagination ul li {
    float: left;
    border-bottom: none;
    padding: 0 0.809em; }
    .nav--pagination ul li:nth-child(1) {
      padding-left: 0; }
  .nav--pagination .active {
    color: #222222;
    font-weight: 500; }

/*================================================
=            Standard navigation look            =
================================================*/
nav {
  font-family: "Graphik Web", sans-serif;
  margin-top: 1.618em; }
  nav ul {
    margin-left: 0;
    width: 100%; }

    

.bt-menu {
  display: none; }
  @media screen and (max-width: 1200px) {
    .bt-menu {
      display: block;
      padding: 0 7.676767% 1.618em;
      text-align: right;
      margin-top: -3.3em;
      color: #888; }
      .bt-menu:hover {
        color: #222222; }
      .bt-menu img {
        margin-top: -0.25em;
        margin-left: .5em; } }
.section:last-of-type {
  margin-bottom: 0; }

.section--main {
  margin-bottom: 1.8333333333rem; }
  @media screen and (max-width: 480px) {
    .section--main {
      margin-bottom: 4.8888888889rem; } }
.section--sub {
  margin-bottom: 0.8888888889rem; }
  @media screen and (max-width: 480px) {
    .section--sub {
      margin-bottom: 1.8333333333rem; } }
.section-header {
  border-bottom: 1px solid #bbb;
  margin-bottom: 1.8333333333rem;
  padding-bottom: 0.2222222222rem; }
  @media screen and (max-width: 480px) {
    .section-header {
      margin-bottom: 1.8333333333rem;
      padding-bottom: 0.6111111111rem; } }
.section-title {
  color: #999999;
  margin: 0; }

.tagline {
  margin-bottom: 1.3125em; }
  @media screen and (max-width: 480px) {
    .tagline {
      margin-bottom: 2.75em; } }
.thumb {
  float: left;
  width: 33.33333%;
  padding: 0.809em 1.618em;
  margin-bottom: 1.618em; }
  .thumb .tint {
    width: 100%;
    height: auto;
    display: block; }
    .thumb .tint img {
      width: 100%;
      height: auto;
      padding-bottom: 0.809em; }
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    .thumb {
      padding: 0.4045em 0.809em; } }
  @media screen and (max-width: 480px) {
    .thumb {
      padding: 0.4045em 0.809em; } }
.thumb-name {
  background-color: #fff;
  padding: 0.809em;
  border-bottom: 2px solid #bbb;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: all 0.5s ease-in-out; }
  .thumb-name > img {
    width: 100%;
    height: auto; }

a .thumb:hover .thumb-name, a .thumb:focus .thumb-name, a .thumb:active .thumb-name {
  transition: all 0.2s ease-in-out;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 -2px 0.2em rgba(0, 0, 0, 0.05); }

/* .border {
  border: 0.382em solid #f8f8f8; } */

.tint {
  position: relative;
  float: left;
  cursor: pointer; }

.tint:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.2);
  transition: background .2s linear; }

.tint:hover:before {
  background: none; }

.portfolio {
  padding-top: 3.236em;
  position: relative; }
  .portfolio h4 {
    margin-bottom: 0;
    margin-top: 0.4045em;
    padding-bottom: 0.25rem;
    font-size: 15px;
    color: #888;
    transition: all 0.8s ease-in-out; }
    .portfolio h4:hover {
      transition: all 0.2s ease-in-out;
      color: #222222; }

.file {
  background: #fff;
  margin-bottom: 1em;
  transition: all 0.8s ease-in-out; }
  .file:hover {
    transition: all 0.2s ease-in-out; }
    .file:hover img {
      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
      -ms-filter: grayscale(0%);
      -o-filter: grayscale(0%);
      filter: grayscale(0%);
      -webkit-filter: none;
              filter: none;
      filter: none;
      /* IE 6-9 */
      transition: all 0.2s ease-in-out; }
  .file img {
    width: 100%;
    height: auto;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-filter: gray;
            filter: gray;
    /* IE6-9 */
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    /* Firefox 10+, Firefox on Android */
    transition: all 0.2s ease-in-out; }

.isStuck {
  max-width: 72.2% !important; }

.filter-wrap {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }
  .filter-wrap::after {
    content: '';
    display: table;
    clear: both; }
  .filter-wrap h3 {
    padding-top: 1rem; }
  .filter-wrap a {
    padding: 0.809em 0;
    border-bottom: 1px solid #f0f0f0;
    float: left;
    display: block;
    margin-right: 3.4482758621%;
    width: 13.7931034483%;
    color: #888; }
    .filter-wrap a:nth-child(6n) {
      margin-right: 0; }
    .filter-wrap a:nth-child(6n+1) {
      clear: left; }
    .filter-wrap a:hover {
      color: inherit;
      border-bottom: 1px solid #222222; }
    @media screen and (min-width: 1024px) and (max-width: 1800px) {
      .filter-wrap a {
        float: left;
        display: block;
        margin-right: 3.4482758621%;
        width: 22.4137931034%; }
        .filter-wrap a:nth-child(6n) {
          margin-right: 3.4482758621%; }
        .filter-wrap a:nth-child(6n+1) {
          clear: none; }
        .filter-wrap a:nth-child(4n) {
          margin-right: 0; }
        .filter-wrap a:nth-child(4n+1) {
          clear: left; } }
    @media screen and (min-width: 481px) and (max-width: 1024px) {
      .filter-wrap a {
        float: left;
        display: block;
        margin-right: 3.4482758621%;
        width: 31.0344827586%; }
        .filter-wrap a:nth-child(4n) {
          margin-right: 3.4482758621%; }
        .filter-wrap a:nth-child(4n+1) {
          clear: none; }
        .filter-wrap a:nth-child(3n) {
          margin-right: 0; }
        .filter-wrap a:nth-child(3n+1) {
          clear: left; } }
    @media screen and (max-width: 480px) {
      .filter-wrap a {
        float: left;
        display: block;
        margin-right: 3.4482758621%;
        width: 48.275862069%; }
        .filter-wrap a:nth-child(3n) {
          margin-right: 3.4482758621%; }
        .filter-wrap a:nth-child(3n+1) {
          clear: none; }
        .filter-wrap a:nth-child(2n) {
          margin-right: 0; }
        .filter-wrap a:nth-child(2n+1) {
          clear: left; } }
  .filter-wrap .active {
    color: #222222; }

header {
  position: relative;
  padding-top: 25%;
  margin-bottom: 2.427em; }
  header small {
    color: #222222;
    padding-left: 2.54026rem; }
  @media screen and (max-width: 1200px) {
    header {
      margin-bottom: 0;
      margin-top: 1.618em;
      padding: 1.618em 7.676767%;
      width: 70%; }
      header small {
        padding-left: 0; } }
  @media screen and (max-width: 480px) {
    header {
      padding: 1.618em 7.676767%;
      width: 70%; } }
.logo {
  font-size: 36px;
  z-index: 99999999999999999999999999999999;
  font-weight: 500;
  margin: 0 2.427rem 0 2.427rem; }
  .logo a {
    color: #020002; }
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    .logo {
      font-size: 25px; } }
  @media screen and (max-width: 1200px) {
    .logo {
      margin: 0; } }
#container .mix {
  display: none; }

.projects {
  padding: 0;
  text-align: justify;
  font-size: 0; }

.projects:after {
  content: '';
  display: inline-block;
  width: 100%; }

.projects .file,
.projects .gap {
  display: inline-block;
  width: 23.5%; }
  @media screen and (min-width: 1024px) and (max-width: 1800px) {
    .projects .file,
    .projects .gap {
      width: 31%; } }
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    .projects .file,
    .projects .gap {
      width: 47.5%; } }
  @media screen and (max-width: 480px) {
    .projects .file,
    .projects .gap {
      width: 100%; } }
.projects .file {
  text-align: left;
  margin-bottom: 2%;
  /*display: none; <-- uncomment this rule for use with MixItUp */ }

.projects .file:before {
  content: '';
  display: inline-block;
  padding-top: 0; }

.filter {
  cursor: pointer; }

/* :::  Blog
---------------------------------------------- */
.blogarticle {
  @apply grid;
  @apply grid-cols-12; }

.date-wrapper {
  @apply col-span-2;
  margin-top: -1.125em; }
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    .date-wrapper {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto; }
      .date-wrapper:first-child {
        margin-left: auto; }
      .date-wrapper:last-child {
        margin-right: auto; } }
.content__blog {
  @apply col-span-7; }
  @media screen and (min-width: 1024px) and (max-width: 1800px) {
    .content__blog {
      @apply col-span-10; } }
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    .content__blog {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto; }
      .content__blog:first-child {
        margin-left: auto; }
      .content__blog:last-child {
        margin-right: auto; } }
  @media screen and (max-width: 480px) {
    .content__blog {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto; }
      .content__blog:first-child {
        margin-left: auto; }
      .content__blog:last-child {
        margin-right: auto; } }
  .content__blog h2 {
    color: #333; }
  .content__blog figure {
    margin: 1.618em 0; }

time {
  font-size: 1.125rem;
  color: #484848; }

.readmore {
  @apply col-span-full; }

  .s-projekt h2 {
    margin-bottom: 0;
    color: #222222; }

section {
   }
  section article {
    padding-bottom: 3.236em; }
    section article ul {
      padding-left: 1em; }

.meta {
  padding: 14px 0;
  margin-bottom: 28px;
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf; }

.text {
  @apply col-span-8;
  margin-bottom: 3.236em; }
  @media screen and (min-width: 1024px) and (max-width: 1800px) {
    .text {
      @apply col-span-9; } }
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    .text {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto; }
      .text:first-child {
        margin-left: auto; }
      .text:last-child {
        margin-right: auto; } }
  @media screen and (max-width: 480px) {
    .text {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto; }
      .text:first-child {
        margin-left: auto; }
      .text:last-child {
        margin-right: auto; } }
.border--bottom {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 3.2em; }

.border--top {
  padding-top: 0.618em;
  border-top: 1px solid #f0f0f0; }

.uppercase {
  text-transform: uppercase; }

.profil-text h3 {
  margin-bottom: 1em;
  text-transform: uppercase; }

.profil-text figure {
  padding: 1.618em 0; }

.bilder {
  padding-top: 14px;
  margin-bottom: 6.472em; }
  .bilder figure {
    padding: 28px 0; }
  .bilder p {
    @apply col-span-6; }
    @media screen and (min-width: 1024px) and (max-width: 1800px) {
      .bilder p {
        @apply col-span-9; } }
    @media screen and (min-width: 481px) and (max-width: 1024px) {
      .bilder p {
        @apply col-span-full; } }
  .bilder h6 {
    @apply col-span-full; }

.kontakt a {
  text-decoration: underline; }

/*
	jQuery.mmenu CSS
*/
/*
	jQuery.mmenu panels CSS
*/
.mm-menu.mm-horizontal > .mm-panel {
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease; }

.mm-menu .mm-hidden {
  display: none; }

.mm-wrapper {
  overflow-x: hidden;
  position: relative; }

.mm-menu,
.mm-menu > .mm-panel {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 0; }

.mm-menu {
  background: inherit;
  display: block;
  overflow: hidden;
  padding: 0; }
  .mm-menu > .mm-panel {
    background: inherit;
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 3.236em;
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0); }
    .mm-menu > .mm-panel.mm-opened {
      -webkit-transform: translate(0%, 0);
      transform: translate(0%, 0);
      -webkit-animation-delay: 250ms;
              animation-delay: 250ms; }
    .mm-menu > .mm-panel.mm-subopened {
      -webkit-transform: translate(-30%, 0);
      transform: translate(-30%, 0); }
    .mm-menu > .mm-panel.mm-highest {
      z-index: 1; }
  .mm-menu .mm-list {
    padding: 1.618em 0; }
  .mm-menu > .mm-list {
    padding-bottom: 0; }
    .mm-menu > .mm-list:after {
      content: '';
      display: block;
      height: 3.236em; }

.mm-panel > .mm-list {
  margin-left: -3.236em;
  margin-right: -3.236em; }
  .mm-panel > .mm-list:first-child {
    padding-top: 0; }

.mm-list,
.mm-list > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0; }

.mm-list {
  font: inherit;
  font-size: 1rem; }
  .mm-list a,
  .mm-list a:hover {
    text-decoration: none; }
  .mm-list > li {
    position: relative; }
    .mm-list > li > a,
    .mm-list > li > span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: inherit;
      line-height: 0em;
      display: block;
      padding: 1.618em 1.618em 1.618em 0;
      margin: 0; }
  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    content: '';
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0; }
  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    width: auto;
    margin-left: 3.236em;
    position: relative;
    left: auto; }
  .mm-list a.mm-subopen {
    background: rgba(3, 2, 1, 0);
    width: 3.236em;
    height: 100%;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2; }
    .mm-list a.mm-subopen:before {
      content: '';
      border-left-width: 1px;
      border-left-style: solid;
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
    .mm-list a.mm-subopen.mm-fullsubopen {
      width: 100%; }
      .mm-list a.mm-subopen.mm-fullsubopen:before {
        border-left: none; }
    .mm-list a.mm-subopen + a,
    .mm-list a.mm-subopen + span {
      padding-right: 0.809em;
      margin-right: 3.236em; }
  .mm-list > li.mm-selected > a.mm-subopen {
    background: transparent; }
  .mm-list > li.mm-selected > a.mm-fullsubopen + a,
  .mm-list > li.mm-selected > a.mm-fullsubopen + span {
    padding-right: 4.045em;
    margin-right: 0; }
  .mm-list a.mm-subclose {
    text-indent: 0em;
    padding-top: 3.236em;
    margin-top: -1.618em; }
  .mm-list > li.mm-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 10px;
    text-transform: uppercase;
    text-indent: 3.236em;
    line-height: 2.427em;
    padding-right: 0.809em; }
  .mm-list > li.mm-spacer {
    padding-top: 3.236em; }
    .mm-list > li.mm-spacer.mm-label {
      padding-top: 2.427em; }
  .mm-list a.mm-subopen:after,
  .mm-list a.mm-subclose:before {
    content: '';
    border: 2px solid transparent;
    display: inline-block;
    width: 7px;
    height: 7px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-bottom: -5px;
    position: absolute;
    bottom: 50%; }
  .mm-list a.mm-subopen:after {
    border-top: none;
    border-left: none;
    right: 18px; }
  .mm-list a.mm-subclose:before {
    border-right: none;
    border-bottom: none;
    margin-bottom: 1.764em;
    left: 22px; }

.mm-menu.mm-vertical .mm-list .mm-panel {
  display: none;
  padding: 1.618em 0 1.618em 1.618em; }
  .mm-menu.mm-vertical .mm-list .mm-panel li:last-child:after {
    border-color: transparent; }

.mm-menu.mm-vertical .mm-list li.mm-opened > .mm-panel {
  display: block; }

.mm-menu.mm-vertical .mm-list > li > a.mm-subopen {
  height: 3.236em; }
  .mm-menu.mm-vertical .mm-list > li > a.mm-subopen:after {
    top: -2.382em;
    bottom: auto; }

.mm-menu.mm-vertical .mm-list > li.mm-opened > a.mm-subopen:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.mm-menu.mm-vertical .mm-list > li.mm-label > a.mm-subopen {
  height: 2.427em; }

.mm-menu
.mm-list {
  padding: 3.6em 4.854em; }
  @media screen and (max-width: 480px) {
    .mm-menu
.mm-list {
      padding: 3.5em 2em; } }
html.mm-opened .mm-page {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }

.mm-menu {
  background: #F8F8F8;
  color: #bbb !important; }
  .mm-menu .mm-list > li:after {
    border-color: #f8f8f8; }
  .mm-menu .mm-list > li > a.mm-subclose {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.3); }
  .mm-menu .mm-list > li > a.mm-subopen:after, .mm-menu .mm-list > li > a.mm-subclose:before {
    border-color: rgba(255, 255, 255, 0.3); }
  .mm-menu .mm-list > li > a.mm-subopen:before {
    border-color: #f8f8f8; }
  .mm-menu .mm-list > li.mm-selected > a:not(.mm-subopen),
  .mm-menu .mm-list > li.mm-selected > span {
    background: rgba(0, 0, 0, 0.1); }
  .mm-menu .mm-list > li.mm-label {
    background: rgba(255, 255, 255, 0.05); }
  .mm-menu.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
  .mm-menu.mm-vertical .mm-list li.mm-opened > ul {
    background: rgba(255, 255, 255, 0.05); }

/*
	jQuery.mmenu offcanvas addon CSS
*/
.mm-page {
  box-sizing: border-box;
  position: relative; }

.mm-slideout {
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease; }

html.mm-opened {
  overflow: hidden;
  position: relative; }
  html.mm-opened body {
    overflow: hidden; }

html.mm-background .mm-page {
  background: inherit; }

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999; }

html.mm-opened #mm-blocker,
html.mm-blocking #mm-blocker {
  display: block; }

.mm-menu.mm-offcanvas {
  display: none;
  position: fixed; }

.mm-menu.mm-current {
  display: block; }

.mm-menu {
  width: 80%;
  min-width: 140px;
  max-width: 0.8; }

html.mm-opening .mm-slideout {
  -webkit-transform: translate(80%, 0);
  transform: translate(80%, 0); }

@media all and (max-width: 175px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(140px, 0);
    transform: translate(140px, 0); } }

@media all and (min-width: 1) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(0.8, 0);
    transform: translate(0.8, 0); } }

/*
	jQuery.mmenu effects extension CSS
*/
html.mm-slide .mm-menu {
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease; }

html.mm-slide.mm-opened .mm-menu {
  -webkit-transform: translateX(-30%);
  transform: translateX(-30%); }

html.mm-slide.mm-opening .mm-menu {
  -webkit-transform: translateX(0%);
  transform: translateX(0%); }

html.mm-slide.mm-right.mm-opened .mm-menu {
  -webkit-transform: translateX(30%);
  transform: translateX(30%); }

html.mm-slide.mm-right.mm-opening .mm-menu {
  -webkit-transform: translateX(0%);
  transform: translateX(0%); }

html.mm-slide.mm-top.mm-opened .mm-menu {
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%); }

html.mm-slide.mm-top.mm-opening .mm-menu {
  -webkit-transform: translateY(0%);
  transform: translateY(0%); }

html.mm-slide.mm-bottom.mm-opened .mm-menu {
  -webkit-transform: translateY(30%);
  transform: translateY(30%); }

html.mm-slide.mm-bottom.mm-opening .mm-menu {
  -webkit-transform: translateY(0%);
  transform: translateY(0%); }

html.mm-zoom-menu .mm-menu {
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease; }

html.mm-zoom-menu.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateX(-30%);
  transform: scale(0.7, 0.7) translateX(-30%);
  -webkit-transform-origin: left center;
  transform-origin: left center; }

html.mm-zoom-menu.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateX(0%);
  transform: scale(1, 1) translateX(0%); }

html.mm-zoom-menu.mm-right.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateX(30%);
  transform: scale(0.7, 0.7) translateX(30%);
  -webkit-transform-origin: right center;
  transform-origin: right center; }

html.mm-zoom-menu.mm-right.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateX(0%);
  transform: scale(1, 1) translateX(0%); }

html.mm-zoom-menu.mm-top.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateY(-30%);
  transform: scale(0.7, 0.7) translateY(-30%);
  -webkit-transform-origin: center top;
  transform-origin: center top; }

html.mm-zoom-menu.mm-top.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateY(0%);
  transform: scale(1, 1) translateY(0%); }

html.mm-zoom-menu.mm-bottom.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateY(30%);
  transform: scale(0.7, 0.7) translateY(30%);
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

html.mm-zoom-menu.mm-bottom.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateY(0%);
  transform: scale(1, 1) translateY(0%); }

html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel {
  -webkit-transform: scale(1.5, 1.5) translateX(100%);
  transform: scale(1.5, 1.5) translateX(100%);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  transition-property: left, -webkit-transform;
  transition-property: transform, left;
  transition-property: transform, left, -webkit-transform; }
  html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel.mm-opened {
    -webkit-transform: scale(1, 1) translateX(0%);
    transform: scale(1, 1) translateX(0%); }
    html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel.mm-opened.mm-subopened {
      -webkit-transform: scale(0.7, 0.7) translateX(-30%);
      transform: scale(0.7, 0.7) translateX(-30%); }

/*
	jQuery.mmenu widescreen extension CSS
	
	To use on widescreens only, include it using a mediaquery:
	<link type="text/css" href="mmenu-widescreen.css" media="all and (min-width: 900px)"/>
*/
@media all and (min-width: 1200px) {
  header {
    display: block; }
  html,
  body,
  html.mm-opened,
  html.mm-opened body {
    overflow: auto; }
  body {
    padding-right: 25% !important;
    position: relative; }
  #mm-blocker {
    display: none !important; }
  .mm-page {
    box-shadow: none !important;
    background: inherit;
    box-sizing: border-box;
    min-height: 100vh;
    height: auto !important;
    margin: 0 !important;
    position: relative !important;
    top: 0 !important;
    z-index: 1; }
  .mm-slideout {
    -webkit-transform: none !important;
    transform: none !important; }
  .mm-menu {
    width: 25% !important;
    z-index: 0;
    top: 0 !important;
    right: 0 !important;
    bottom: auto !important;
    left: auto !important; }
    .mm-menu:first-child, .mm-menu.mm-current {
      display: block; }
  .mm-menu
.mm-list {
    padding: 0 2.427em; } }

/*
	jQuery.mmenu position extension CSS
*/
.mm-menu.mm-top,
.mm-menu.mm-bottom {
  width: 100%;
  min-width: 100%;
  max-width: 100%; }

.mm-menu.mm-right {
  left: auto;
  right: 0; }

.mm-menu.mm-bottom {
  top: auto;
  bottom: 0; }

html.mm-right.mm-opening .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  transform: translate(-80%, 0); }

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    transform: translate(-140px, 0); } }

@media all and (min-width: 1) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-0.8, 0);
    transform: translate(-0.8, 0); } }

/*
		jQuery.mmenu z-position extension CSS
	*/
html.mm-front .mm-slideout {
  -webkit-transform: none !important;
  transform: none !important;
  z-index: 0 !important; }

.mm-menu.mm-front {
  z-index: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); }

html.mm-opened.mm-next .mm-page {
  box-shadow: none; }

.mm-menu.mm-front, .mm-menu.mm-next {
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100%, 0); }
  .mm-menu.mm-front.mm-right, .mm-menu.mm-next.mm-right {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0); }

.mm-menu.mm-front.mm-top {
  -webkit-transform: translate(0, -100%);
  transform: translate(0, -100%); }

.mm-menu.mm-front.mm-bottom {
  -webkit-transform: translate(0, 100%);
  transform: translate(0, 100%); }

html.mm-opening .mm-menu.mm-front, html.mm-opening .mm-menu.mm-next {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

.mm-menu.mm-front.mm-top, .mm-menu.mm-front.mm-bottom {
  height: 80%;
  min-height: 140px;
  max-height: 880px; }

.teaser {
  position: relative;
  padding: 7.676767%;
  background: white; }

.teaser--txt {
  position: absolute;
  line-height: 1.2em;
  width: 20em;
  padding: 0.5em 0; }
  .teaser--txt small {
    position: absolute;
    width: 10em; }

figure.fluidratio {
  padding-top: 66.6666666667%;
  height: 100px;
  background-size: cover;
  -moz-background-size: cover;
  /* Firefox 3.6 */
  background-position: center;
  /* Internet Explorer 7/8 */ }
  @media screen and (max-width: 480px) {
    figure.fluidratio {
      padding-top: 100%;
      height: 0px;
      background-size: cover;
      -moz-background-size: cover;
      /* Firefox 3.6 */
      background-position: center;
      /* Internet Explorer 7/8 */ } }
figure {
  margin-bottom: 0; }

body {
  border-top: 2vh solid #F8F8F8; }
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    body {
      border-top: 0; } }
