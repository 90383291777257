@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */

/* Bestehendes CSS von Böll */
@import "oldcss_bak.css";


/* -----------------------------
    TYPOGRAPHY
------------------------------*/
/* --- Font --- */
@import "../fonts/1403-GLRSOR.css";

blockquote p {
    text-indent: -0.5ch;
}

.text {
    @apply text-neutral-500;
}

.text p > a:after {
    content: "→";
    display: inline-block;
    padding-left: 0.1em;
    transition: all .2s ease-in-out;
    @apply hover:translate-x-1;
}

.text h2 {
    @apply text-base;
    @apply text-neutral-900;
    @apply font-medium;
    @apply border-t;
    @apply border-neutral-200;
    @apply pt-3;
    @apply pb-6;
}

.text figure {
    @apply mt-4;
    @apply mb-6;
}
/* -----------------------------
    FLOW
------------------------------*/

.flow > * + *:not(.flow--no-margin) {
    margin-block-start: var(--flow-space, 2em);
}

h2.flow>*+*:not(.flow--no-margin) {
    margin-block-start: var(--flow-space, 2em);
}


/* --- Lazysiizes --- */

figure {
    width: 100%;
}

img[data-sizes="auto"] {
    display: block;
    width: 100%;
}

/* class name must matches the value of `ratio` setting. */

.lazysrcset-ratio {
    position: relative;
}

.lazysrcset-ratio>img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.lazysrcset-ratio>img:after {
    display: block;
    width: 100%;
    height: 0;
    content: '';
}




/* -----------------------------
fade in Animation 
------------------------------*/

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade-in {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}