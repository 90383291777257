/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2013 Schwartzco Inc.
        License: 1403-GLRSOR     
*/


@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-Regular-Web.eot');
  src: url('../fonts/Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'),
     url('../fonts/Graphik-Regular-Web.woff') format('woff'),
     url('../fonts/Graphik-Regular-Web.ttf') format('truetype'),
     url('../fonts/Graphik-Regular-Web.svg#Graphik Web') format('svg');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}

.Graphik-Regular-Web {
  font-family: 'Graphik Web';
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-Medium-Web.eot');
  src: url('../fonts/Graphik-Medium-Web.eot?#iefix') format('embedded-opentype'),
     url('../fonts/Graphik-Medium-Web.woff') format('woff'),
     url('../fonts/Graphik-Medium-Web.ttf') format('truetype'),
     url('../fonts/Graphik-Medium-Web.svg#Graphik Web') format('svg');
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}

.Graphik-Medium-Web {
  font-family: 'Graphik Web';
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-MediumItalic-Web.eot');
  src: url('../fonts/Graphik-MediumItalic-Web.eot?#iefix') format('embedded-opentype'),
     url('../fonts/Graphik-MediumItalic-Web.woff') format('woff'),
     url('../fonts/Graphik-MediumItalic-Web.ttf') format('truetype'),
     url('../fonts/Graphik-MediumItalic-Web.svg#Graphik Web') format('svg');
  font-weight:  500;
  font-style:   italic;
  font-stretch: normal;
}

.Graphik-MediumItalic-Web {
  font-family: 'Graphik Web';
  font-weight:  500;
  font-style:   italic;
  font-stretch: normal;
}

